<template>
  <div class="py-12">
    <v-container>
      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col cols="12">
                <p class="danger" v-if="error">{{ error }}</p>
              </v-col>
              <v-col cols="12" md="6"> 
                <date-input :input="fromDate" @change="(val) => form.StartAt = val"></date-input>
              </v-col>
              <v-col cols="12" md="6"> 
                <date-input :input="toDate" @change="(val) => form.EndAt = val"></date-input>
              </v-col>
              <v-col cols="12"> 
                <v-text-field
                  label="price"
                  prepend-icon="mdi-money"
                  type="number"
                  v-model="Price"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  :loading="loading"
                  @click.prevent="submit"
                  class="w-full"
                  color="primary"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script >
import {
  required,
  carValidation,
} from "@/utils/validations/validations.ts";
import { TicketCreate } from "@/repositories/ticket";
import { Upload } from "@/repositories/global";
import Date from "@/utils/form/components/Date.vue"
import {fromDate,toDate} from '@/utils/form/inputs/InputStore.ts'
// import {snackBar} from '@/utils/Helpers'
export default {
  components : {
    "date-input" : Date
  },
  data() {
    return {
      fromDate,
      toDate,
      img: null,
      error: null,
      loading: false,
      valid: true,
      carValidation,
      inputs: [
        {
          label: "type",
          key: "Type",
          type: "text",
        },
        {
          label: "model",
          key: "Model",
          type: "number",
        },

        {
          label: "mileage",
          key: "Mileage",
          type: "number",
        },
      ],
      errors: {},
      form: {},
      required,
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate();
      if (this.valid) {
        this.loading = true;
        this.form.UserId = parseInt(this.$route.params.id)
        this.form.ServiceId = 1
        TicketCreate(this.form)
          .then((d) => {
            this.error = null;
            this.loading = false;
            this.error = null;
            this.$router.push("/users")
            // this.$store.commit('ui/snackBar' , 'user created successfully')
            // this.$store.commit('user/user' , d)
          })
          .catch((e) => {
            this.error = "this email already exists";
            this.loading = false;
            window.scrollTo({
              top: 300,
              left: 0,
              behavior: "smooth",
            });
          });
      }
    },
  },
  watch: {
    img: {
      handler: function (val) {
        let formData = new FormData();
        formData.append("file", val);
        Upload(formData).then((d) => {
          console.log(d);
          this.form.Img = d;
        });
        console.log(val);
      },
    },
  },
};
</script>
