import Api from '@/utils/axios/Api';
const Http = Api.getInstance();







export const TicketCreate = (payload:Object) => {
    return new Promise((resolve, reject) => {
        Http.post('ticket' , payload)
        .then((d) => {
            resolve(d)
        }).catch(e => {
            reject(e)
        })
    })
}

